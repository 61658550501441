<nz-layout class="uiPageLayout">
  <nz-spin [nzSpinning]="pdfDowload">
    <nz-content class="uiPageContent uiQuoteContainer">
      <div class="uiVersionContainer">
        <span>{{ 'quote' | translate }} n. {{ quoteId }}{{ 'quoteVersion' | translate }}</span>
        <nz-select class="uiVersionSelect" [(ngModel)]="selectQuote" (ngModelChange)="onChangeVersion($event)">
          <nz-option *ngFor="let q of allQuotes" [nzValue]="q" [nzLabel]="q.versione"></nz-option>
        </nz-select>
        Copertina:
        <!-- ******************** 2023/07/10 Alberto Baggio **************************************************************************************************************************************************** -->
        <nz-select class="uiVersionSelect" style="width: 150px" [(ngModel)]="selectedCover" [disabled]="standardCoverDisabled || !enableEdit" (ngModelChange)="standardCover($event)">
          <nz-option *ngFor="let standardCover of standardCoverList; let index = index" [nzDisabled]="index == 0" [nzValue]="standardCover" [nzLabel]="standardCover.file"></nz-option>
        </nz-select>
        <nz-upload class="fileUpload" nzType="upload" [nzMultiple]="false" [nzFilter]="uploadFilters" [nzLimit]="1" [nzSize]="2000" [nzShowUploadList]="false" [nzFileType]="'image/png,image/jpeg'" nzAction="{{ uploadUrl }}" [nzBeforeUpload]="beforeUpload" [nzCustomRequest]="handleUpload">
          <button nz-button [disabled]="!enableEdit" [nzLoading]="isUploading" (click)="handlePreview($event)">
            <i *ngIf="!allegato" nz-icon nzType="upload"></i>
            <i *ngIf="allegato" nz-icon nzType="file-image" nzTheme="outline"></i>
            <span *ngIf="!allegato" style="padding-right: 15px">Carica copertina</span>
            <span *ngIf="allegato">{{ allegato }}</span>
            <a nz-button nzType="text" *ngIf="allegato" class="deleteIcon" (click)="removeFile($event)" nzDanger>
              <i nz-icon nzType="delete" nzDanger></i>
            </a>
          </button>
        </nz-upload>
        <!-- ************************************************************************************************************************************************************************************************* -->
        <ng-template #tplPreview let-params>
          <!-- ******************** 2023/08/10 Alberto Baggio **************************************************************************************************************************************************** -->
          <embed style="width: 100%; margin-top: 30px; height: 80vh" [src]="params.file | safeUrl" type="application/pdf" />
        </ng-template>
        Preventivista: <input *ngIf="selectQuote" class="uiAuthorInput" style="margin-right: 16px" placeholder="Nome e cognome del preventivista" nz-input [(ngModel)]="selectQuote.preventivista" />
        Scheda anagrafica:
        <nz-upload
          class="fileUpload"
          nzType="upload"
          [nzMultiple]="false"
          [nzFilter]="uploadFiltersPdf"
          [nzLimit]="1"
          [nzSize]="2000"
          [nzShowUploadList]="false"
          [nzFileType]="'application/pdf'"
          nzAction="{{ uploadUrlAnagrafica }}"
          [nzBeforeUpload]="beforeUploadAnagrafica"
          [nzCustomRequest]="handleUploadAnagrafica"
        >
          <button nz-button [disabled]="!enableEdit" [nzLoading]="isUploadingAnagrafica" (click)="handlePreviewAnagrafica($event)">
            <i *ngIf="!anagrafica" nz-icon nzType="upload"></i>
            <i *ngIf="anagrafica" nz-icon nzType="file-image" nzTheme="outline"></i>
            <span *ngIf="!anagrafica" style="padding-right: 15px">Carica scheda anagrafica</span>
            <span *ngIf="anagrafica">{{ anagrafica }}</span>
            <a nz-button nzType="text" *ngIf="anagrafica" class="deleteIcon" (click)="removeFileAnagrafica($event)" nzDanger>
              <i nz-icon nzType="delete" nzDanger></i>
            </a>
          </button>
        </nz-upload>
        <ng-template #tplPreviewAnagrafica let-params>
          <embed style="width: 100%; margin-top: 30px; height: 80vh" [src]="params.file | safeUrl" type="application/pdf" />
        </ng-template>
        Scheda privacy:
        <nz-upload
          class="fileUpload"
          nzType="upload"
          [nzMultiple]="false"
          [nzFilter]="uploadFiltersPdf"
          [nzLimit]="1"
          [nzSize]="2000"
          [nzShowUploadList]="false"
          [nzFileType]="'application/pdf'"
          nzAction="{{ uploadUrlPrivacy }}"
          [nzBeforeUpload]="beforeUploadPrivacy"
          [nzCustomRequest]="handleUploadPrivacy"
        >
          <button nz-button [disabled]="!enableEdit" [nzLoading]="isUploadingPrivacy" (click)="handlePreviewPrivacy($event)">
            <i *ngIf="!privacy" nz-icon nzType="upload"></i>
            <i *ngIf="privacy" nz-icon nzType="file-image" nzTheme="outline"></i>
            <span *ngIf="!privacy" style="padding-right: 15px">Carica scheda privacy</span>
            <span *ngIf="privacy">{{ privacy }}</span>
            <a nz-button nzType="text" *ngIf="privacy" class="deleteIcon" (click)="removeFilePrivacy($event)" nzDanger>
              <i nz-icon nzType="delete" nzDanger></i>
            </a>
          </button>
        </nz-upload>
        <ng-template #tplPreviewPrivacy let-params>
          <!-- ************************************************************************************************************************************************************************************ -->
          <embed style="width: 100%; margin-top: 30px; height: 80vh" [src]="params.file | safeUrl" />
        </ng-template>
      </div>

      <nz-tabset nzType="card" class="uiQuoteTab">
        <nz-tab nzTitle="Intestazione">
          <div class="uiQuoteHead">
            <ng-container *ngIf="!edit; else editTemplate">
              <div class="uiQuoteHeadForm">
                <div nz-row [nzGutter]="16" class="uiHeadRow">
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteDataP' | translate }}</span> <br />
                      <p *ngIf="quoteContact && quoteContact.data_preventivo">
                        {{ quoteContact.data_preventivo * 1000 | date: 'dd/MM/yyyy' }}
                      </p>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteDataC' | translate }}</span>
                      <br />
                      <p *ngIf="quoteContact">{{ quoteContact.data_creazione * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteDataU' | translate }}</span> <br />
                      <p *ngIf="quoteContact">
                        {{ quoteContact.data_modifica * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}
                      </p>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteForniture' | translate }}</span> <br />
                      <input class="uiYellowBkgBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.fornitura" />
                      <!-- <nz-select disabled class="uiVersionSelect" nzMode="tags" *ngIf="quoteContact" [(ngModel)]="quoteContact.fornitura" style="width: 100%;">
                                                <nz-option *ngFor="let q of listOfForniture" [nzValue]="q" [nzLabel]="q"></nz-option>
                                            </nz-select> -->
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteOBJ' | translate }}</span> <br />
                      <input class="uiYellowBkgBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.note" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'NUMERO COMMESSA' }}</span> <br />
                      <input class="uiYellowBkgBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.commessa" />
                    </div>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiHeadRow">
                  <div nz-col [nzSpan]="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCId' | translate }}</span>
                      <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.id_contatto" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteRagSoc' | translate }}</span>
                      <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.ragSoc" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteAttne' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.attne" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="4" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteSett' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.tipologia" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteSell' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.cVend" />
                    </div>
                  </div>
                </div>
                <div nz-row class="uiHeadRow">
                  <!-- MAX: 24-->
                  <div nz-col nzSpan="8" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteAdr' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.indirizzo" />
                    </div>
                  </div>
                  <div nz-col nzSpan="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCAP' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.cap" />
                    </div>
                  </div>
                  <div nz-col nzSpan="4" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCity' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.localita" />
                    </div>
                  </div>
                  <div nz-col nzSpan="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quotePV' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.cd_provincia" />
                    </div>
                  </div>
                  <div nz-col nzSpan="4" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteEmail' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.email" style="word-break: break-all" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCF' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.codicefiscale" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quotePI' | translate }}</span> <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.partitaiva" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="uiQuoteHeadBtns">
                <button nz-button [disabled]="!enableEdit" class="uiHeadButton" (click)="addContact()" nzTooltipTitle="{{ 'selectContact' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-address-card uiQuoteBtn"></i>
                </button>
                <nz-divider nzOrientation="Horizontal"></nz-divider>
                <button nz-button [disabled]="!enableEdit" class="uiHeadButton" (click)="startEdit()" nzTooltipTitle="{{ 'editHead' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-edit uiQuoteBtn"></i>
                </button>
              </div>
            </ng-container>
            <ng-template #editTemplate>
              <div class="uiQuoteHeadForm">
                <div nz-row [nzGutter]="16" class="uiHeadRow">
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteDataP' | translate }}</span> <br />
                      <nz-date-picker class="uiYellowBkgBtn" *ngIf="editCache" [(ngModel)]="editCache.data_preventivo" (ngModelChange)="onChangeDate($event)" nzFormat="dd/MM/yyyy"> </nz-date-picker>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteDataC' | translate }}</span>
                      <br />
                      <p *ngIf="editCache">
                        {{ quoteContact.data_creazione * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}
                      </p>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteDataU' | translate }}</span>
                      <br />
                      <p *ngIf="editCache">
                        {{ quoteContact.data_modifica * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}
                      </p>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteForniture' | translate }}</span> <br />
                      <nz-select class="uiYellowBkgSelect" nzMode="tags" [(ngModel)]="editCache.fornitura" style="width: 100%">
                        <nz-option *ngFor="let q of listOfForniture" [nzValue]="q" [nzLabel]="q"> </nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteOBJ' | translate }}</span> <br />
                      <input class="uiYellowBkgBtn" *ngIf="editCache" nz-input [(ngModel)]="editCache.note" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="3" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'NUMERO COMMESSA' }}</span> <br />
                      <input class="uiYellowBkgBtn" *ngIf="quoteContact" nz-input [(ngModel)]="editCache.commessa" />
                    </div>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiHeadRow">
                  <div nz-col [nzSpan]="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCId' | translate }}</span>
                      <br />
                      <input class="uiDisabledBtn" *ngIf="quoteContact" nz-input disabled [(ngModel)]="quoteContact.id_contatto" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteRagSoc' | translate }}</span>
                      <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.ragSoc" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteAttne' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.attne" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="4" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteSett' | translate }}</span> <br />
                      <nz-select class="uiVersionSelect" [(ngModel)]="editCache.tipologia" style="width: 100%">
                        <nz-option *ngFor="let q of sectSelect" [nzValue]="q" [nzLabel]="q"> </nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <!-- #################################### CVEND -->
                  <div nz-col [nzSpan]="6" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteSell' | translate }}</span> <br />
                      <nz-select class="uiVersionSelect" [(ngModel)]="editCache.cd_venditore" style="width: 100%">
                        <nz-option *ngFor="let q of sellers" [nzValue]="q.cd_venditore" [nzLabel]="q.label"> </nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
                <div nz-row class="uiHeadRow">
                  <!-- MAX: 24-->
                  <div nz-col nzSpan="8" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteAdr' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.indirizzo" />
                    </div>
                  </div>
                  <div nz-col nzSpan="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCAP' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.cap" />
                    </div>
                  </div>
                  <div nz-col nzSpan="4" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCity' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.localita" />
                    </div>
                  </div>
                  <div nz-col nzSpan="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quotePV' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.cd_provincia" />
                    </div>
                  </div>
                  <div nz-col nzSpan="4" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteEmail' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.email" style="word-break: break-all" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quoteCF' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.codicefiscale" />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="2" class="uiHeadCol">
                    <div class="uiInnerHeadCol">
                      <span>{{ 'quotePI' | translate }}</span> <br />
                      <input *ngIf="editCache" nz-input [(ngModel)]="editCache.partitaiva" />
                    </div>
                  </div>
                </div>
                <div nz-row class="uiHeadRow" [nzGutter]="24">
                  <!-- MAX: 24-->
                </div>
              </div>
              <div class="uiQuoteHeadBtns">
                <button nz-button class="uiHeadButton" (click)="cancelEdit()" nzTooltipTitle="{{ 'cancelModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-times uiQuoteBtn"></i>
                </button>
                <nz-divider nzOrientation="Horizontal"></nz-divider>
                <button nz-button class="uiHeadButton" (click)="saveEdit()" nzTooltipTitle="{{ 'saveModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-save uiQuoteBtn"></i>
                </button>
              </div>
            </ng-template>
          </div>
        </nz-tab>
        <nz-tab nzTitle="{{ 'quoteGroups' | translate }}">
          <div class="uiQuoteGroup">
            <ng-container *ngIf="!editGroups; else editGroupTemplate">
              <div class="uiQuoteGroupForm">
                <div nz-row [nzGutter]="16" class="uiGroupRow uiGroupHeadRow">
                  <div nz-col [nzSpan]="2" class="uiGroupCol uiGroupColorCol">
                    <div class="uiInnerGroupCol">
                      <span>{{ 'quoteGroup' | translate }}</span> <br />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="8" class="uiGroupCol">
                    <div class="uiInnerGroupCol">
                      <span>{{ 'quoteGroupName' | translate }}</span> <br />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="10" class="uiGroupCol">
                    <div class="uiInnerGroupCol">
                      <span>{{ 'quoteOptions' | translate }}</span> <br />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="4" class="uiGroupCol">
                    <div class="uiInnerGroupCol"><span>Totale:</span> <br /></div>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiGroupRow uiGroupBodyRow" *ngFor="let g of groups">
                  <!-- ***************************************** -->
                  <div nz-row [nzGutter]="16" class="uiGroupRow uiGroupBodyRow" *ngIf="g.id !== 0">
                    <div nz-col [nzSpan]="2" class="uiGroupCol uiGroupBodyCol">
                      <div class="uiInnerGroupCol">
                        <div [ngClass]="g.class"></div>
                      </div>
                    </div>
                    <div nz-col [nzSpan]="8" class="uiGroupCol" style="display: flex; align-items: center">
                      <div class="uiInnerGroupCol" style="width: 100%">
                        <input class="uiDisabledBtn" nz-input [(ngModel)]="g.name" disabled />
                      </div>
                    </div>
                    <div nz-col [nzSpan]="10" class="uiGroupCol uiGroupBodyOptions">
                      <div style="width: 100%" class="uiInnerGroupCol">
                        <nz-radio-group style="width: 100%" [(ngModel)]="g.option" disabled>
                          <label style="width: 50%" nz-radio nzValue="A" class="uiGroupOtion">Completo</label>
                          <label nz-radio nzValue="B" class="uiGroupOtion">Gruppi senza prezzi singoli</label>
                          <br />
                          <label style="width: 50%" nz-radio nzValue="C" class="uiGroupOtion">Solo totale</label>
                          <label nz-radio nzValue="D" class="uiGroupOtion">Completo senza prezzi</label>
                          <br />
                          <label style="width: 50%" nz-radio nzValue="E" class="uiGroupOtion">Completo senza prezzi e q.tà</label>
                          <label nz-radio nzValue="F" class="uiGroupOtion">Gruppi senza prezzi singoli e q.tà</label>
                          <br />
                          <label style="width: 50%" nz-radio nzValue="G" class="uiGroupOtion">Solo totale senza totale gruppo</label>
                          <label nz-radio nzValue="H" class="uiGroupOtion">Opzionale</label>
                          <br />
                          <label nz-radio nzValue="I" class="uiGroupOtion">Disattivato</label>
                        </nz-radio-group>
                      </div>
                    </div>
                    <div nz-col [nzSpan]="4" class="uiGroupCol uiGroupBodyOptions">
                      <div class="uiInnerGroupCol">
                        <span class="uiGroupTotal" *ngIf="g.option !== '' && g.option !== 'H'">{{ groupTotal[g.id].total | number: '1.2-2' | noComma }} €</span>
                      </div>
                    </div>
                    <!-- **************************************************** -->
                  </div>
                </div>
                <!-- <div *ngIf="g.id !=="></div> -->
              </div>
              <div class="uiQuoteGroupsBtns">
                <button nz-button class="uiGroupButton" (click)="startGroupEdit()" [disabled]="!enableEdit">
                  <i class="fad fa-edit uiQuoteBtn" nzTooltipTitle="{{ 'editGroups' | translate }}" nzTooltipPlacement="top" nz-tooltip></i>
                </button>
              </div>
            </ng-container>
            <ng-template #editGroupTemplate>
              <div class="uiQuoteGroupForm">
                <div nz-row [nzGutter]="16" class="uiGroupRow uiGroupHeadRow">
                  <div nz-col [nzSpan]="2" class="uiGroupCol uiGroupColorCol">
                    <div class="uiInnerGroupCol">
                      <span>{{ 'quoteGroup' | translate }}</span> <br />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="12" class="uiGroupCol">
                    <div class="uiInnerGroupCol">
                      <span>{{ 'quoteGroupName' | translate }}</span> <br />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="10" class="uiGroupCol">
                    <div class="uiInnerGroupCol">
                      <span>{{ 'quoteOptions' | translate }}</span> <br />
                    </div>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiGroupRow uiGroupBodyRow" *ngFor="let g of editCacheGroups">
                  <div nz-row [nzGutter]="16" class="uiGroupRow uiGroupBodyRow" *ngIf="g.id !== 0">
                    <div nz-col [nzSpan]="2" class="uiGroupCol uiGroupBodyCol">
                      <div class="uiInnerGroupCol">
                        <div [ngClass]="g.class"></div>
                      </div>
                    </div>
                    <div nz-col [nzSpan]="12" class="uiGroupCol" style="display: flex; align-items: center">
                      <div class="uiInnerGroupCol" style="width: 100%">
                        <input class="uiYellowBkgBtn" nz-input [(ngModel)]="g.name" />
                      </div>
                    </div>
                    <div nz-col [nzSpan]="10" class="uiGroupCol uiGroupBodyOptions">
                      <div style="width: 100%" class="uiInnerGroupCol">
                        <nz-radio-group style="width: 100%" [(ngModel)]="g.option">
                          <label style="width: 50%" nz-radio nzValue="A" class="uiGroupOtion">Completo</label>
                          <label nz-radio nzValue="B" class="uiGroupOtion">Gruppi senza prezzi singoli</label>
                          <br />
                          <label style="width: 50%" nz-radio nzValue="C" class="uiGroupOtion">Solo totale</label>
                          <label nz-radio nzValue="D" class="uiGroupOtion">Completo senza prezzi</label>
                          <br />
                          <label style="width: 50%" nz-radio nzValue="E" class="uiGroupOtion">Completo senza prezzi e q.tà</label>
                          <label nz-radio nzValue="F" class="uiGroupOtion">Gruppi senza prezzi singoli e q.tà</label>
                          <br />
                          <label style="width: 50%" nz-radio nzValue="G" class="uiGroupOtion">Solo totale senza totale gruppo</label>
                          <label nz-radio nzValue="H" class="uiGroupOtion">Opzionale</label>
                          <br />
                          <label nz-radio nzValue="I" class="uiGroupOtion">Disattivato</label>
                        </nz-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uiQuoteGroupsBtns">
                <button nz-button class="uiGroupSaveButton" (click)="cancelGroupEdit()" nzTooltipTitle="{{ 'cancelModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-times uiQuoteBtn"></i>
                </button>
                <nz-divider nzOrientation="Horizontal"></nz-divider>
                <button nz-button class="uiGroupSaveButton" (click)="saveGroupEdit()" nzTooltipTitle="{{ 'saveModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-save uiQuoteBtn"></i>
                </button>
              </div>
            </ng-template>
          </div>
        </nz-tab>
        <nz-tab nzTitle="{{ 'quoteConditions' | translate }}">
          <div class="uiQuoteCondition">
            <ng-container *ngIf="!editCondition; else editConditionTemplate">
              <div class="uiQuoteConditionForm">
                <div nz-row [nzGutter]="16" class="uiConditionRow uiConditionHeadRow">
                  <div nz-col [nzSpan]="6" class="uiConditionCol">
                    <div class="uiInnerConditionCol">
                      <span>{{ 'quoteConditionName' | translate }}</span> <br />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol">
                    <div class="uiInnerConditionCol">
                      <span>{{ 'quoteCondition' | translate }}</span> <br />
                    </div>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiConditionRow uiConditionBodyRow" *ngFor="let c of conditions">
                  <div nz-col [nzSpan]="6" class="uiConditionCol uiConditionBodyCol">
                    <div class="uiInnerConditionCol">
                      {{ c.name }}
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol">
                    <div class="uiInnerConditionCol">
                      <!-- ***************** 2023/07/06 Alberto Baggio ****************************************************************************************************************************************** -->
                      <div>
                        <input class="uiDisabledBtn" nz-input [(ngModel)]="c.option" disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uiQuoteGroupsBtns">
                <button nz-button [disabled]="!enableEdit" class="uiGroupButton" (click)="startConditionEdit()" nzTooltipTitle="{{ 'editCondition' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-edit uiQuoteBtn"></i>
                </button>
              </div>
            </ng-container>
            <ng-template #editConditionTemplate>
              <div class="uiQuoteConditionForm">
                <div nz-row [nzGutter]="16" class="uiConditionRow uiConditionHeadRow">
                  <div nz-col [nzSpan]="6" class="uiConditionCol">
                    <div class="uiInnerConditionCol">
                      <span>{{ 'quoteConditionName' | translate }}</span> <br />
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol">
                    <div class="uiInnerConditionCol">
                      <span>{{ 'quoteCondition' | translate }}</span> <br />
                    </div>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiConditionRow uiConditionBodyRow" *ngFor="let c of editCacheConditions">
                  <div nz-col [nzSpan]="6" class="uiConditionCol uiConditionBodyCol">
                    <div class="uiInnerConditionCol">
                      {{ c.name }}
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol" *ngIf="c.name === 'Inizio lavori'">
                    <div class="uiInnerConditionCol" style="display: flex">
                      <!-- ***************** 2023/07/06 Alberto Baggio ****************************************************************************************************************************************** -->
                      <input nz-input [(ngModel)]="c.option" />
                      <nz-date-picker id="myDateStart" [(ngModel)]="myDateStart" (ngModelChange)="onDateCondition($event, c)" nzFormat="dd/MM/yyyy"> </nz-date-picker>
                      <!-- ************************************************************************************************************************************************************************************** -->
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol" *ngIf="c.name === 'Consegna'">
                    <div class="uiInnerConditionCol" style="display: flex">
                      <!-- ***************** 2023/07/06 Alberto Baggio ****************************************************************************************************************************************** -->
                      <input nz-input [(ngModel)]="c.option" />
                      <nz-date-picker id="myDateEnd" [(ngModel)]="myDateEnd" (ngModelChange)="onDateCondition($event, c)" nzFormat="dd/MM/yyyy"> </nz-date-picker>
                      <!-- ************************************************************************************************************************************************************************************** -->
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol" *ngIf="c.name === 'Validità offerta'">
                    <div class="uiInnerConditionCol">
                      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'selectDurationType' | translate }}" [(ngModel)]="c.option">
                        <nz-option nzValue="15 giorni" nzLabel="15 giorni"></nz-option>
                        <nz-option nzValue="30 giorni" nzLabel="30 giorni"></nz-option>
                        <nz-option nzValue="60 giorni" nzLabel="60 giorni"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol" *ngIf="c.type === 'tipo_pagamento'">
                    <div class="uiInnerConditionCol">
                      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'selectPaymentType' | translate }}" [(ngModel)]="c.option">
                        <nz-option *ngFor="let pay of paymentSettings" [nzValue]="pay" [nzLabel]="pay"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="18" class="uiConditionCol" *ngIf="c.type === 'tipo_fatturazione'">
                    <div class="uiInnerConditionCol">
                      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'selectBillingType' | translate }}" [(ngModel)]="c.option">
                        <nz-option *ngFor="let bil of billingSettings" [nzValue]="bil" [nzLabel]="bil"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uiQuoteGroupsBtns">
                <button nz-button class="uiGroupSaveButton" (click)="cancelConditionEdit()" nzTooltipTitle="{{ 'cancelModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-times uiQuoteBtn"></i>
                </button>
                <nz-divider nzOrientation="Horizontal"></nz-divider>
                <button nz-button class="uiGroupSaveButton" (click)="saveConditionsEdit()" nzTooltipTitle="{{ 'saveModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-save uiQuoteBtn"></i>
                </button>
              </div>
            </ng-template>
          </div>
        </nz-tab>
        <!-- ************* 2023/07/13 Alberto Baggio ******************************************************************************************************************************************************************************************************************************************** -->
        <nz-tab nzTitle="Opzionali">
          <div class="uiQuoteGroup">
            <div class="uiQuoteBody">
              <nz-table #dataTable nzBordered nzSize="small" [nzData]="quoteProds" [nzPageSize]="100" [nzLoading]="loading" nzPaginationPosition="top" [nzSimple]="true" class="uiAdminTable" nzTableLayout="fixed" [nzFrontPagination]="false" [nzShowPagination]="false">
                <thead>
                  <tr>
                    <th nzAlign="center" nzWidth="45px"></th>
                    <th nzAlign="center" nzWidth="70px">{{ 'prodId' | translate }}</th>
                    <th nzAlign="center" nzWidth="20px"></th>
                    <th nzAlign="center">{{ 'prodName' | translate }}</th>
                    <th nzAlign="center" nzWidth="55px"><span nz-icon nzType="picture" nzTheme="outline"></span></th>
                    <th nzAlign="center">{{ 'prodDesc' | translate }}</th>
                    <th nzAlign="center" nzWidth="100px">{{ 'prodUmQ' | translate }}</th>
                    <th nzAlign="center">{{ 'prodNote' | translate }}</th>
                    <th nzAlign="center" nzWidth="90px">{{ 'prodCUnit' | translate }}</th>
                    <th nzAlign="center" nzWidth="90px">{{ 'prodCTot' | translate }}</th>
                    <th nzAlign="center" nzWidth="120px">{{ 'prodRic' | translate }}</th>
                    <th nzAlign="center" nzWidth="90px">{{ 'prodVUnit' | translate }}</th>
                    <th nzAlign="center" nzWidth="90px">{{ 'prodVTot' | translate }}</th>
                    <th nzAlign="center" nzWidth="50px"></th>
                  </tr>
                </thead>
                <tbody class="uiAdminTableBody" cdkDropList (cdkDropListDropped)="drop($event)">
                  <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="{ uiColoredRow: i % 2 !== 0 }" cdkDrag>
                    <ng-container *ngIf="data.color.option === 'H'">
                      <td nzAlign="center">
                        {{ data.ordine }}
                      </td>

                      <td nzAlign="center">
                        {{ data.cd_prodotto }}
                      </td>
                      <td style="padding: 0">
                        <div style="height: 82px">
                          <button
                            nz-dropdown
                            nzTrigger="click"
                            nz-button
                            style="width: 100%; height: 100%"
                            [disabled]="!enableEdit"
                            [nzDropdownMenu]="menu"
                            class="uiColorGroup"
                            [ngClass]="data.color ? data.color.class : 'uiTransparentDot'"
                            nzTooltipTitle="{{ 'selectRowGroup' | translate }}"
                            nzTooltipPlacement="top"
                            nz-tooltip
                          >
                            <i class="fad fa-palette"></i>
                          </button>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <li nz-menu-item (click)="setRowColor('null', data.cd_riga)">
                                <div style="display: flex; flex-direction: row">
                                  <div class="uiColorGroup uiTransparentDot"></div>
                                  <span style="font-size: 10px; margin-left: 5px">(nessuno)</span>
                                </div>
                              </li>
                              <li *ngFor="let g of groups" nz-menu-item [ngClass]="g.option !== '' ? '' : 'uiUndefinedOption'" (click)="setRowColor(g, data.cd_riga)">
                                <div style="display: flex; flex-direction: row">
                                  <div [ngClass]="g.class"></div>
                                  <span style="font-size: 10px; margin-left: 5px">{{ g.name }}</span>
                                </div>
                              </li>
                            </ul>
                          </nz-dropdown-menu>
                        </div>
                      </td>
                      <td nzAlign="left">
                        {{ data.nome }}
                      </td>
                      <td nzAlign="center">
                        <nz-badge *ngIf="data.immagini.length > 0" [nzCount]="data.immagini.length">
                          <a (click)="showImages(data.cd_riga)">
                            <i class="fad fa-images" style="font-size: 30px"></i>
                          </a>
                        </nz-badge>
                      </td>
                      <td nzAlign="left" class="uiEipsisCol">
                        <div [innerHTML]="data.descrizione"></div>
                        <a (click)="showDescription(data.cd_riga)" class="uiOpenText" *ngIf="data.descrizione" nzTooltipTitle="{{ 'showDescription' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                          <i class="fad fa-search" style="font-size: 15px"></i>
                        </a>
                      </td>
                      <td nzAlign="center" class="uiQtaCol">
                        <a (click)="addQ(data.cd_riga, -0.1)" class="uiLeftAdd" nzTooltipTitle="{{ 'removeQuantity' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                          <i class="fad fa-minus" style="font-size: 15px"></i>
                        </a>
                        {{ data.quantita }}
                        <a (click)="addQ(data.cd_riga, 0.1)" class="uiRightAdd" nzTooltipTitle="{{ 'addQuantity' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                          <i class="fad fa-plus" style="font-size: 15px"></i>
                        </a>
                        <nz-divider></nz-divider>
                        {{ data.um }}
                      </td>
                      <td nzAlign="left" class="uiEipsisCol">
                        <div [innerHTML]="data.note"></div>
                        <a (click)="showNote(data.cd_riga)" class="uiOpenText" *ngIf="data.note" nzTooltipTitle="{{ 'showNotes' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                          <i class="fad fa-search" style="font-size: 15px"></i>
                        </a>
                      </td>
                      <!-- COSTO -->
                      <td nzAlign="right">
                        <span *ngIf="data.ricarico > 0"> {{ data.unit_manodopera + data.unit_materiale | number: '1.2-2' | noComma }} € </span>
                        <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ data.unit_manodopera + data.unit_materiale | number: '1.2-2' | noComma }} € </span>
                      </td>
                      <td nzAlign="right">
                        <span *ngIf="data.ricarico > 0"> {{ (data.unit_manodopera + data.unit_materiale) * data.quantita | number: '1.2-2' | noComma }} € </span>
                        <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ (data.unit_manodopera + data.unit_materiale) * data.quantita | number: '1.2-2' | noComma }} € </span>
                      </td>
                      <td nzAlign="right">
                        <!-- <button nz-button nzType="default">
                                                    {{data.ricarico}}
                                                </button> -->
                        <nz-input-number-group nzCompact style="display: flex; flex-direction: row">
                          <nz-input-number #ric [ngModel]="data.ricarico" (ngModelChange)="updateRicarico($event, data)" [nzStep]="0.01" (mousedown)="$event.stopPropagation()"></nz-input-number>
                          <div style="display: flex; flex-direction: column; margin-left: 5px">
                            <button nz-button [disabled]="data.bakRicarico == null || data.bakRicarico === data.ricarico" nzSize="small" style="height: 50%; font-size: 9px" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="{{ 'tooltip.cancel' | translate }}" (click)="cancelRicarico(data)">
                              <span nz-icon nzType="undo" nzTheme="outline"></span>
                            </button>
                            <button nz-button [disabled]="data.bakRicarico == null || data.bakRicarico === data.ricarico" nzSize="small" style="height: 50%; font-size: 9px" nz-tooltip nzTooltipPlacement="bottom" nzTooltipTitle="{{ 'tooltip.save' | translate }} AAAAAA" (click)="saveRicarico(data)">
                              <span nz-icon nzType="save" nzTheme="outline"></span>
                            </button>
                          </div>
                        </nz-input-number-group>
                      </td>
                      <!-- VENDITA -->
                      <td nzAlign="right">
                        <span *ngIf="data.ricarico > 0"> {{ (data.unit_manodopera + data.unit_materiale) / data.ricarico | number: '1.2-2' | noComma }} € </span>
                        <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ data.unit_manodopera + data.unit_materiale | number: '1.2-2' | noComma }} € </span>
                      </td>
                      <td nzAlign="right">
                        <span *ngIf="data.ricarico > 0"> {{ ((data.unit_manodopera + data.unit_materiale) * data.quantita) / data.ricarico | number: '1.2-2' | noComma }} € </span>
                        <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ (data.unit_manodopera + data.unit_materiale) * data.quantita | number: '1.2-2' | noComma }} € </span>
                      </td>

                      <td nzAlign="center">
                        <a (click)="editQuoteRow(data.cd_riga)" *ngIf="enableEdit" nzTooltipTitle="{{ 'editRow' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                          <i class="fad fa-edit uiAdminUserAction"></i>
                        </a>
                        <br />
                        <a (click)="copyQuoteRow(data.cd_riga)" *ngIf="enableEdit" nzTooltipTitle="{{ 'copyRow' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                          <i class="fad fa-copy uiAdminUserAction"></i>
                        </a>
                        <br />
                        <a nz-popconfirm nzPopconfirmTitle="{{ 'deleteRow' | translate }}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteQuoteRow(data.cd_riga)" *ngIf="enableEdit" nzTooltipTitle="{{ 'deleteRowP' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                          <i class="fad fa-trash uiAdminUserAction"></i>
                        </a>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </nz-tab>
        <!-- ******************************************************************************************************************************************************************************************************* -->
        <nz-tab nzTitle="{{ 'quoteClauses' | translate }}">
          <div class="uiQuoteClauses">
            <ng-container *ngIf="!editClauses; else editClausesTemplate">
              <div class="uiQuoteClausesForm">
                <div nz-row [nzGutter]="16" class="uiClausesRow uiClausesBodyRow">
                  <div nz-col [nzSpan]="24" class="uiClausesCol">
                    <div class="uiClausesInner" [innerHTML]="clauses"></div>
                  </div>
                </div>
              </div>
              <div class="uiQuoteGroupsBtns">
                <button nz-button class="uiGroupButton" [disabled]="!enableEdit" (click)="startClausesEdit()" nzTooltipTitle="{{ 'editClauses' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-edit uiQuoteBtn"></i>
                </button>
              </div>
            </ng-container>
            <ng-template #editClausesTemplate>
              <div class="uiQuoteClausesForm">
                <div nz-row [nzGutter]="16" class="uiClausesRow uiClausesBodyRow">
                  <div nz-col [nzSpan]="24" class="uiClausesCol">
                    <quill-editor [ngClass]="translate.currentLang === 'it' ? 'uiQuillIta uiCluauseTextarea' : 'uiCluauseTextarea'" [styles]="{ height: '400px' }" [(ngModel)]="editCacheClauses"></quill-editor>
                  </div>
                </div>
              </div>
              <div class="uiQuoteGroupsBtns">
                <button nz-button class="uiGroupSaveButton" (click)="cancelClausesEdit()" nzTooltipTitle="{{ 'cancelModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-times uiQuoteBtn"></i>
                </button>
                <nz-divider nzOrientation="Horizontal"></nz-divider>
                <button nz-button class="uiGroupSaveButton" (click)="saveClausesEdit()" nzTooltipTitle="{{ 'saveModification' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-save uiQuoteBtn"></i>
                </button>
              </div>
            </ng-template>
          </div>
        </nz-tab>
      </nz-tabset>
      <div class="uiQuoteBody">
        <button nz-button nzType="primary" class="uiAddButton" (click)="addProd()" nzSize="large" [disabled]="!enableEdit">
          <i class="fad fa-plus"></i>
          {{ 'btn.add' | translate }}
        </button>

        <nz-input-group [nzSuffix]="suffixIconSearch" class="uiSearchbar">
          <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i class="fad fa-search"></i>
        </ng-template>
        <!-- [nzPageIndex]="getQuoteTableIndex()" (nzPageIndexChange)="saveTableIndex($event)" -->
        <nz-table #dataTable nzBordered nzSize="small" [nzData]="quoteProds" [nzPageSize]="100" [nzLoading]="loading" nzPaginationPosition="top" [nzSimple]="true" class="uiAdminTable" nzTableLayout="fixed" [nzFrontPagination]="false" [nzShowPagination]="false">
          <thead>
            <tr>
              <th nzAlign="center" nzWidth="45px"></th>

              <th nzAlign="center" nzWidth="70px">{{ 'prodId' | translate }}</th>
              <th nzAlign="center" nzWidth="20px"></th>
              <th nzAlign="center">{{ 'prodName' | translate }}</th>
              <th nzAlign="center" nzWidth="55px"><span nz-icon nzType="picture" nzTheme="outline"></span></th>
              <th nzAlign="center">{{ 'prodDesc' | translate }}</th>
              <th nzAlign="center" nzWidth="100px">{{ 'prodUmQ' | translate }}</th>
              <th nzAlign="center">{{ 'prodNote' | translate }}</th>
              <th nzAlign="center" nzWidth="90px">{{ 'prodCUnit' | translate }}</th>
              <th nzAlign="center" nzWidth="90px">{{ 'prodCTot' | translate }}</th>
              <th nzAlign="center" nzWidth="120px">{{ 'prodRic' | translate }}</th>
              <th nzAlign="center" nzWidth="90px">{{ 'prodVUnit' | translate }}</th>
              <th nzAlign="center" nzWidth="90px">{{ 'prodVTot' | translate }}</th>
              <th nzAlign="center" nzWidth="50px"></th>
            </tr>
          </thead>
          <tbody class="uiAdminTableBody" cdkDropList (cdkDropListDropped)="drop($event)">
            <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="{ uiColoredRow: i % 2 !== 0 }" cdkDrag>
              <ng-container *ngIf="data.color.option !== 'H'">
                <td nzAlign="center">
                  {{ data.ordine }}
                </td>

                <td nzAlign="center">
                  {{ data.cd_prodotto }}
                </td>
                <td style="padding: 0">
                  <div style="height: 82px">
                    <button
                      nz-dropdown
                      nzTrigger="click"
                      nz-button
                      style="width: 100%; height: 100%"
                      [disabled]="!enableEdit"
                      [nzDropdownMenu]="menu"
                      class="uiColorGroup"
                      [ngClass]="data.color ? data.color.class : 'uiTransparentDot'"
                      nzTooltipTitle="{{ 'selectRowGroup' | translate }}"
                      nzTooltipPlacement="top"
                      nz-tooltip
                    >
                      <i class="fad fa-palette"></i>
                    </button>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item (click)="setRowColor('null', data.cd_riga)">
                          <div style="display: flex; flex-direction: row">
                            <div class="uiColorGroup uiTransparentDot"></div>
                            <span style="font-size: 10px; margin-left: 5px">(nessuno)</span>
                          </div>
                        </li>
                        <li *ngFor="let g of groups" nz-menu-item [ngClass]="g.option !== '' ? '' : 'uiUndefinedOption'" (click)="setRowColor(g, data.cd_riga)">
                          <div style="display: flex; flex-direction: row">
                            <div [ngClass]="g.class"></div>
                            <span style="font-size: 10px; margin-left: 5px">{{ g.name }}</span>
                          </div>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </td>
                <td nzAlign="left">
                  {{ data.nome }}
                </td>
                <td nzAlign="center">
                  <nz-badge *ngIf="data.immagini.length > 0" [nzCount]="data.immagini.length">
                    <a (click)="showImages(data.cd_riga)">
                      <i class="fad fa-images" style="font-size: 30px"></i>
                    </a>
                  </nz-badge>
                </td>
                <td nzAlign="left" class="uiEipsisCol">
                  <div [innerHTML]="data.descrizione"></div>
                  <a (click)="showDescription(data.cd_riga)" class="uiOpenText" *ngIf="data.descrizione" nzTooltipTitle="{{ 'showDescription' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                    <i class="fad fa-search" style="font-size: 15px"></i>
                  </a>
                </td>
                <td nzAlign="center" class="uiQtaCol">
                  <a (click)="addQ(data.cd_riga, -0.1)" class="uiLeftAdd" nzTooltipTitle="{{ 'removeQuantity' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                    <i class="fad fa-minus" style="font-size: 15px"></i>
                  </a>
                  {{ data.quantita }}
                  <a (click)="addQ(data.cd_riga, 0.1)" class="uiRightAdd" nzTooltipTitle="{{ 'addQuantity' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                    <i class="fad fa-plus" style="font-size: 15px"></i>
                  </a>
                  <nz-divider></nz-divider>
                  {{ data.um }}
                </td>
                <td nzAlign="left" class="uiEipsisCol">
                  <div [innerHTML]="data.note"></div>
                  <a (click)="showNote(data.cd_riga)" class="uiOpenText" *ngIf="data.note" nzTooltipTitle="{{ 'showNotes' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                    <i class="fad fa-search" style="font-size: 15px"></i>
                  </a>
                </td>
                <!-- COSTO -->
                <td nzAlign="right">
                  <span *ngIf="data.ricarico > 0"> {{ data.unit_manodopera + data.unit_materiale | number: '1.2-2' | noComma }} € </span>
                  <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ data.unit_manodopera + data.unit_materiale | number: '1.2-2' | noComma }} € </span>
                </td>
                <td nzAlign="right">
                  <span *ngIf="data.ricarico > 0"> {{ (data.unit_manodopera + data.unit_materiale) * data.quantita | number: '1.2-2' | noComma }} € </span>
                  <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ (data.unit_manodopera + data.unit_materiale) * data.quantita | number: '1.2-2' | noComma }} € </span>
                </td>
                <td nzAlign="right">
                  <!-- <button nz-button nzType="default">
                                  {{data.ricarico}}
                              </button> -->
                  <nz-input-number-group nzCompact style="display: flex; flex-direction: row">
                    <nz-input-number #ric [ngModel]="data.ricarico" (ngModelChange)="updateRicarico($event, data)" [nzStep]="0.01" (mousedown)="$event.stopPropagation()"></nz-input-number>
                    <div style="display: flex; flex-direction: column; margin-left: 5px">
                      <button nz-button [disabled]="data.bakRicarico == null || data.bakRicarico === data.ricarico" nzSize="small" style="height: 50%; font-size: 9px" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="{{ 'tooltip.cancel' | translate }}" (click)="cancelRicarico(data)">
                        <span nz-icon nzType="undo" nzTheme="outline"></span>
                      </button>
                      <button nz-button [disabled]="data.bakRicarico == null || data.bakRicarico === data.ricarico" nzSize="small" style="height: 50%; font-size: 9px" nz-tooltip nzTooltipPlacement="bottom" nzTooltipTitle="{{ 'tooltip.save' | translate }} AAAA" (click)="saveRicarico(data)">
                        <span nz-icon nzType="save" nzTheme="outline"></span>
                      </button>
                    </div>
                  </nz-input-number-group>
                </td>
                <!-- VENDITA -->
                <td nzAlign="right">
                  <span *ngIf="data.ricarico > 0"> {{ (data.unit_manodopera + data.unit_materiale) / data.ricarico | number: '1.2-2' | noComma }} € </span>
                  <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ data.unit_manodopera + data.unit_materiale | number: '1.2-2' | noComma }} € </span>
                </td>
                <td nzAlign="right">
                  <span *ngIf="data.ricarico > 0"> {{ ((data.unit_manodopera + data.unit_materiale) * data.quantita) / data.ricarico | number: '1.2-2' | noComma }} € </span>
                  <span *ngIf="!data.ricarico || data.ricarico <= 0"> {{ (data.unit_manodopera + data.unit_materiale) * data.quantita | number: '1.2-2' | noComma }} € </span>
                </td>

                <td nzAlign="center">
                  <a (click)="editQuoteRow(data.cd_riga)" *ngIf="enableEdit" nzTooltipTitle="{{ 'editRow' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                    <i class="fad fa-edit uiAdminUserAction"></i>
                  </a>
                  <br />
                  <a (click)="copyQuoteRow(data.cd_riga)" *ngIf="enableEdit" nzTooltipTitle="{{ 'copyRow' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                    <i class="fad fa-copy uiAdminUserAction"></i>
                  </a>
                  <br />
                  <a nz-popconfirm nzPopconfirmTitle="{{ 'deleteRow' | translate }}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteQuoteRow(data.cd_riga)" *ngIf="enableEdit" nzTooltipTitle="{{ 'deleteRowP' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                    <i class="fad fa-trash uiAdminUserAction"></i>
                  </a>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-content>
    <div class="uiQuoteSummary" *ngIf="!loading">
      <span class="uiQuoteSummaryQ"
        >{{ 'quoteTotRic' | translate }} <span *ngIf="prevTot > 0">{{ (prevCost * 100) / prevTot / 100 | number: '1.2-2':'it-IT' }}</span
        ><span *ngIf="prevTot == 0">--</span>
      </span>
      <span class="uiQuoteSummaryT">{{ 'quoteTotC' | translate }} € {{ prevCost | number: '1.2-2':'it-IT' }}</span>
      <span class="uiQuoteSummaryT">{{ 'quoteTot' | translate }} € {{ prevTot | number: '1.2-2':'it-IT' }}</span>
    </div>
    <nz-footer class="uiPageFooter uiQuoteFooter">
      <button *ngIf="enableEdit" nz-button nz-popconfirm nzPopconfirmTitle="{{ 'exitMessage' | translate }}" nzPopconfirmPlacement="bottom" (nzOnConfirm)="back()" nzType="primary" class="uiLeftButton" nzSize="large">
        <i class="fad fa-home"></i>
        {{ 'returnHome' | translate }}
      </button>
      <button *ngIf="!enableEdit" nz-button (click)="back()" nzType="primary" class="uiLeftButton" nzSize="large">
        <i class="fad fa-home"></i>
        {{ 'returnHome' | translate }}
      </button>
      <!-- <button nz-button nz-popconfirm nzPopconfirmTitle="{{'deleteAlert' | translate}}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteQuote()" nzType="primary" class="uiRightProd" nzSize="large">
            {{'deleteQuote' | translate}}
            <i class="fad fa-trash"></i>
        </button> -->

      <button *ngIf="enableEdit" nz-button nz-popconfirm nzPopconfirmTitle="{{ 'completeAlert' | translate }}" nzPopconfirmPlacement="top" (nzOnConfirm)="completeQuote()" nzType="primary" class="uiRightProd" nzSize="large">
        {{ 'complete' | translate }}
        <i class="fad fa-check"></i>
      </button>
      <button *ngIf="!enableEdit" nz-button nz-popconfirm [disabled]="true" class="uiRightProd" nzSize="large" style="background-color: rgba(249, 255, 181, 0.8)">
        {{ 'completed' | translate }}
        <i class="fad fa-flag-checkered"></i>
      </button>

      <button nz-button class="uiRightProd" [disabled]="saveButtonDisabled()" nz-button nz-popconfirm nzPopconfirmTitle="Prima di vedere l'anteprima devi salvare, vuoi salvare?" nzPopconfirmPlacement="bottom" (nzOnConfirm)="previewPdf()" nzSize="large">
        {{ 'preview' | translate }}
        <i class="fad fad-swap-color fa-eye"></i>
      </button>
      <button nz-button nzType="primary" class="uiRightProd" (click)="saveQuote(true)" [disabled]="saveButtonDisabled()" nzSize="large">
        {{ 'save' | translate }}
        <i class="fad fa-save"></i>
      </button>
    </nz-footer>
  </nz-spin>
</nz-layout>
