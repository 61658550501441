import { ImgFilterService } from './img-filter.service';
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { DataService } from "./data.service";
import { loadavg } from 'os';

export default class ImgFilterFunctions {

  constructor(protected data: DataService, protected message: NzMessageService, protected translate: TranslateService, public imgFilterService: ImgFilterService) { }

  dataResult: any[] = [];
  dataResultDisplay: any[] = [];
  editId: number | null = null;
  customNameFilter: string = '';
  tagsSelectedFilter: string[] = [];
  loading = true;
  showTable = false;

  imgNameSelected: string | null;

  async deleteImg(id) {
    this.loading = true;

    const i = this.dataResult.findIndex((el) => el.id === id);
    this.dataResult.splice(i, 1);

    const iOfDisplay = this.dataResultDisplay.findIndex((el) => el.id === id);
    this.dataResultDisplay.splice(iOfDisplay, 1);

    try {
      const result = await this.data.deleteImgFilter({ img_name: this.dataResult[i].name }).toPromise();
      console.log(result);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;

  }

  editImgFilter(id) {
    if (this.editId === null) {
      this.editId = id;
    } else {
      this.message.error('Devi salvare prima di modificare un altro elemento');
    }
  }

  addDataToImg() {
    this.dataResult = this.imgFilterService.addDataToImg(this.dataResult);
    this.dataResult = this.dataResult.sort((a, b) => {
      // Prima controlla lo stato di "selected" per dare priorità a quelli con selected: true
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;

      // Se entrambi hanno selected true o false, ordina alfabeticamente per custom_name
      if (!a.selected && !b.selected) {
        return a.custom_name.localeCompare(b.custom_name);
      }

      // Mantieni l'ordine originale per quelli con selected: true
      return 0;
    });
    console.log(this.dataResult);
    this.dataResultDisplay = [
      ...this.dataResult,
    ];
  }

  async saveFilter() {

    const customName = this.dataResult.find(el => el.id == this.editId)?.custom_name ?? '';
    let tagsSelected = this.dataResult.find(el => el.id == this.editId)?.tags ?? [];

    if (tagsSelected.length > 0) {
      tagsSelected = tagsSelected.filter((el) => {
        if (this.imgFilterService.tagsList.find((tag) => tag.id === el)) {
          return el;
        }
      });
    }

    console.log(tagsSelected);
    console.log(customName);
    if (!this.checkWordCount(customName)) {
      this.message.error('Il titolo deve contenere dalle 3 alle 4 parole');
      return;
    }
    if (tagsSelected.length === 0) {
      this.message.error('Devi selezionare almeno un tag');
      return;
    }
    const elementToSave = {
      img_name: this.dataResult.find(el => el.id == this.editId).name,
      custom_name: customName,
      tags: JSON.stringify(tagsSelected)
    };
    console.log({ elementToSave });



    try {
      const result = await this.data.setImgFilter(elementToSave).toPromise();
      this.message.success(this.translate.instant('success'));
    } catch (error) {
      console.log(error); this.message.error(this.translate.instant('error'));
    }

    this.editId = null;


  }


  searchImg() {
    this.loading = true;
    if (this.customNameFilter.length > 2 || this.tagsSelectedFilter.length > 0) {
      this.dataResultDisplay = this.dataResult.filter((el) => {
        if (el.custom_name?.toLocaleLowerCase().includes(this.customNameFilter.toLocaleLowerCase())) {
          if (this.tagsSelectedFilter.length > 0) {
            return this.tagsSelectedFilter.some((tag) => el.tags?.includes(tag));
          }
          return true;
        }
      });
    } else {
      this.dataResultDisplay = this.dataResult;
    }
    this.loading = false;
  }

  checkWordCount(str) {
    // \b\w+\b matches a word, \s+ matches whitespace, {2,3} ensures 2-3 spaces
    const regex = /^([\p{L}\p{N}]+(?:'[\p{L}\p{N}]+)?)((\s+[\p{L}\p{N}]+(?:'[\p{L}\p{N}]+)?){2,3})$/u;
    return regex.test(str.trim());
  }
}
