<nz-layout class="uiModalLayout">
  <nz-content>
    <div nz-row [nzGutter]="16" style="margin-bottom: 30px">
      <div nz-col class="gutter-row" nzSpan="10">
        <label for="custom_name"><span nz-icon nzType="search"></span> Cerca titolo</label>
        <div class="flex">
          <input id="custom_name" nz-input placeholder="Basic usage" [(ngModel)]="customNameFilter" placeholder="Nome dell'immagine" (ngModelChange)="searchImg()" />
        </div>
      </div>
      <div nz-col class="gutter-row" nzSpan="10">
        <label for="tag-list"><span nz-icon nzType="search"></span> Cerca tag</label>
        <div class="flex">
          <nz-select style="width: 100%" id="tag-list" [(ngModel)]="tagsSelectedFilter" nzMode="multiple" nzPlaceHolder="Tag dell'immagine" (ngModelChange)="searchImg()">
            <nz-option *ngFor="let tag of imgFilterService.tagsList" [nzLabel]="tag.name" [nzValue]="tag.id"></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col class="gutter-row flex justify-content-center align-items-center" nzSpan="4">
        <button [disabled]="editId !== null" nz-button nzType="primary" nzSize="large" (click)="showTable = !showTable" class="w-100">Tabella / Lista</button>
      </div>
    </div>
    <div *ngIf="loading" class="loading-modal">
      <nz-spin nzSimple></nz-spin>
    </div>
    <div *ngIf="!loading" class="modal-main-container">
      <nz-table *ngIf="showTable" #virtualTable [nzBordered]="true" [nzVirtualItemSize]="83" [nzData]="dataResultDisplay" [nzVirtualForTrackBy]="trackByIndex" [nzFrontPagination]="false" [nzShowPagination]="false" [nzScroll]="{ x: '100%', y: '50vh' }">
        <thead>
          <tr>
            <th nzLeft>Immagine</th>
            <th nzLeft>Titolo</th>
            <th nzLeft>Tag</th>
            <th nzCenter>Azione</th>
          </tr>
        </thead>
        <tbody>
          <ng-template nz-virtual-scroll let-data let-index="id">
            <tr>
              <td nzAlign="center">
                <div>
                  <nz-badge [nzCount]="iconTemplate" class="uiImgBadge">
                    <div style="float: right; position: absolute; left: 0px; z-index: 1000"></div>
                    <a class="uiImgAnchor" (click)="selectImg(data)">
                      <img [src]="data.url" loading="lazy" class="uiImg" />
                    </a>
                  </nz-badge>
                  <ng-template #iconTemplate>
                    <i *ngIf="data.selected" nz-icon nzType="check-circle" class="ant-scroll-number-custom-component uiSelectedIcon" nzTheme="fill"></i>
                  </ng-template>
                </div>
              </td>
              <td>
                <div class="flex flex-column justify-content-center w-100">
                  <input *ngIf="!data.name.startsWith('OFF')" [nzBorderless]="editId != data.id" [disabled]="editId != data.id" id="custom_name" nz-input placeholder="Basic usage" [(ngModel)]="data.custom_name" placeholder="Associa un nome all'immagine" />
                  <div *ngIf="data.name.startsWith('OFF')">{{ data.name }}</div>
                </div>
              </td>
              <td>
                <nz-select *ngIf="!data.name.startsWith('OFF')" [nzBorderless]="editId != data.id" [nzDisabled]="editId != data.id" style="width: 100%" id="tag-list" [(ngModel)]="data.tags" nzMode="multiple" nzPlaceHolder="Associa un tag all'immagine">
                  <nz-option *ngFor="let tag of this.imgFilterService.tagsList" [nzLabel]="tag.name" [nzValue]="tag.id"></nz-option>
                </nz-select>
              </td>
              <td nzAlign="center" *ngIf="!data.name.startsWith('OFF')">
                <button style="margin-right: 10px" nz-button nz-popconfirm nzPopconfirmTitle="Vuoi cancellare l'immagine dal prodotto?" *ngIf="auth.getUser().ruolo !== 'utente' && editId != data.id" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteImg(data.id)">
                  <i class="fad fa-trash"></i>
                </button>
                <button nz-button *ngIf="auth.getUser().ruolo !== 'utente' && editId != data.id" (click)="editImgFilter(data.id)">
                  <i class="fad fa-edit"></i>
                </button>
                <button nz-button *ngIf="auth.getUser().ruolo !== 'utente' && editId == data.id" (click)="saveFilter()">
                  <i class="fad fa-save"></i>
                </button>
              </td>
              <td *ngIf="data.name.startsWith('OFF')"></td>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>

      <div *ngIf="!showTable" nz-row style="width: 100%; height: 50vh">
        <div *ngFor="let data of dataResultDisplay; let i = index" nz-col nzSpan="4" style="height: 190px">
          <div class="flex justify-content-between" style="padding: 10px">
            <div class="flex w-100">
              <nz-badge [nzCount]="iconTemplate" class="uiImgBadge w-100" style="text-align: center">
                <a class="uiImgAnchor" (click)="selectImg(data)">
                  <img [src]="data.url" loading="lazy" class="uiImg" style="height: 150px; object-fit: contain; width: 175px" />
                </a>
                <div style="text-align: center">{{ data.custom_name }}</div>
              </nz-badge>
            </div>
          </div>
          <ng-template #iconTemplate>
            <i *ngIf="data.selected" nz-icon nzType="check-circle" class="ant-scroll-number-custom-component uiSelectedIcon" nzTheme="fill"></i>
          </ng-template>
        </div>
      </div>
    </div>
  </nz-content>
  <div *nzModalFooter class="uiModalFooter">
    <nz-upload [nzDisabled]="editId !== null" class="uiImgUpload" [nzShowUploadList]="false" nzAction="{{ uploadURL }}" [nzCustomRequest]="customImgReq" nzAccept="image/png, image/jpeg">
      <button nz-button class="uiUploadBtn">
        <i class="fad fa-file-upload"></i>
        {{ 'upload' | translate }}
      </button>
    </nz-upload>
    <button nz-button nzType="primary" class="uiSaveBtn" (click)="onClickSave()">
      <i class="fad fa-check"></i>
      {{ 'save' | translate }}
    </button>
  </div>
</nz-layout>
