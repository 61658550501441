<nz-header class="uiAdminHeader">
  <div nz-row class="uiHeaderAdminRow">
    <div class="uiHeaderAdminLeftCol" nz-col nzSpan="16">
      <a href="/history"><img class="uiUncollapsedLogo" src="../../../assets/logo.png" alt="logo" *ngIf="!isCollapsed" /></a>
      <a href="/history"><img class="uiCollapsedLogo" src="../../../assets/logo-small.png" alt="logo" *ngIf="isCollapsed" /></a>
      <span class="uiSidebarTrigger" (click)="isCollapsed = !isCollapsed">
        <i class="trigger" [ngClass]="isCollapsed ? 'fad fa-angle-double-right uiAdminIcon' : 'fad fa-angle-double-left uiAdminIcon'"></i>
      </span>
      <span style="font-size: 1.3em">{{ 'admin' | translate }}</span>
    </div>
    <div nz-col nzSpan="8" class="uiHeaderAdminRightCol">
      <i class="fad fa-user uiHeaderHomeBtn" *ngIf="auth.isLoggedIn()"></i>
      <span class="uiLogoutSpan" *ngIf="auth.isLoggedIn()">{{ currentUser['cognome'] }}</span
      ><i *ngIf="auth.isLoggedIn()" class="fad fa-sign-out uiLogoutBtn" (click)="auth.logout()"></i>
    </div>
  </div>
</nz-header>
<nz-content>
  <div class="uiAdminContainer">
    <nz-sider class="uiAdminSidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
      <ul class="uiAdminMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed" *ngIf="!isCollapsed">
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./products"><i class="fad fa-cubes uiAdminIcon"></i>{{ 'prodManagment' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./categories"><i class="fad fa-cubes uiAdminIcon"></i>{{ 'catManagment' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter *ngIf="auth.getUser().ruolo === 'superadmin'">
          <a routerLink="./users"><i class="fad fa-users uiAdminIcon"></i>{{ 'userManagment' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter *ngIf="!isSeller">
          <a routerLink="./sellers"><i class="fad fa-user-lock uiAdminIcon"></i>{{ 'sellerManagment' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./quotes"><i class="fad fa-th-list uiAdminIcon"></i>{{ 'quoteManagment' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./customers"><i class="fad fa-street-view uiAdminIcon"></i>{{ 'customerManagment' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./settings"><i class="fad fa-cog uiAdminIcon"></i>{{ 'settings' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./tags"><i class="fad fa-tag uiAdminIcon"></i>{{ 'Tags' | translate }}</a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./history"><i class="fad fa-home uiAdminIcon"></i>{{ 'returnHome' | translate }}</a>
        </li>
      </ul>
      <ul class="uiAdminMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed" *ngIf="isCollapsed">
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./products"><i class="fad fa-cubes uiAdminIcon"></i></a>
        </li>
        <li nz-menu-item nzMatchRouter *ngIf="auth.getUser().ruolo === 'superadmin'">
          <a routerLink="./users"><i class="fad fa-users uiAdminIcon"></i></a>
        </li>
        <li nz-menu-item nzMatchRouter *ngIf="auth.getIsSeller().toString() !== 'null'">
          <a routerLink="./sellers"><i class="fad fa-user-lock uiAdminIcon"></i></a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./quotes"><i class="fad fa-th-list uiAdminIcon"></i></a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./customers"><i class="fad fa-street-view uiAdminIcon"></i></a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./settings"><i class="fad fa-cog uiAdminIcon"></i></a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="./history"><i class="fad fa-home uiAdminIcon"></i></a>
        </li>
      </ul>
    </nz-sider>
    <nz-layout class="uiAdminInnerLayout">
      <nz-content class="uiAdminContent">
        <div class="uiAdminInnerContent">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </div>
</nz-content>
