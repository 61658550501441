import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ImgFilterService {

  constructor(private data: DataService) { this.getTags(); }

  tagsList: any[] = [];

  imageFiltersList: any[] = [];

  async getImgFilters() {
    try {
      const results = await this.data.getImgFilter().toPromise();
      this.imageFiltersList = results.map((el) => {
        if (!el.tags) el.tags = "[]";
        el.tags = JSON.parse(el.tags);
        return el;
      });

    } catch (error) {
      console.error(error);
    }
  }

  async getTags() {
    try {
      const results = await this.data.getTags().toPromise();
      this.tagsList = results;
    } catch (error) {
      console.error(error);
    }
  }

  addDataToImg(dataResult) {
    return dataResult.map((el) => {
      const imageFilter = this.imageFiltersList.find((img) => {
        return img.img_name === el.name;
      });
      el.custom_name = imageFilter?.custom_name ?? '';
      el.tags = imageFilter?.tags ?? [];
      return el;
    });
  }


}
