<nz-layout class="uiPageLayout">
  <nz-spin [nzSpinning]="pdfDowload">
    <nz-content class="uiPageContent uiHistoryContainer">
      <button nz-button nzSize="large" nzType="primary" class="uiAddButton" type="button" (click)="newQuote()">
        <i class="fad fa-pencil uiAdminIcon"></i>
        {{ 'newQuote' | translate }}
      </button>
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)" />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i class="fad fa-search"></i>
      </ng-template>
      <nz-table #dataTable nzBordered nzSize="middle" [nzData]="displayedData" [nzPageSize]="5" [nzLoading]="loading" nzPaginationPosition="top" [nzSimple]="true" class="uiAdminTable">
        <thead>
          <tr>
            <th nzAlign="center">Id</th>
            <th nzAlign="center">{{ 'quoteV' | translate }}</th>
            <th nzAlign="center" [nzFilters]="listAuthors" [nzFilterFn]="filterAuthor">{{ 'quoteAuthor' | translate }}</th>
            <th nzAlign="center">{{ 'quoteCustomer' | translate }}</th>
            <th nzAlign="center">{{ 'quoteLadtData' | translate }}</th>
            <th nzAlign="center" [nzFilters]="listSellers" [nzFilterFn]="filterSeller">{{ 'quoteSeller' | translate }}</th>
            <th nzAlign="center">{{ 'quoteSector' | translate }}</th>
            <th nzAlign="center">{{ 'quoteState' | translate }}</th>
            <th nzAlign="center" colspan="6">{{ 'quoteAction' | translate }}</th>
          </tr>
        </thead>
        <tbody class="uiAdminTableBody">
          <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="{ uiYellowRow: data.stato === 'In corso', uiGreyRow: data.stato === 'Bloccato', '': data.stato === 'Completato' }">
            <td nzLeft="0px" nzAlign="center">
              {{ data.cd_preventivo }}
            </td>
            <td nzLeft="0px" nzAlign="center">
              {{ data.versione }}
            </td>
            <td nzLeft="0px" nzAlign="center">
              {{ data.uNome }} {{ data.uCognome }}
              <br />
              {{ data.uEmail }}
            </td>
            <td nzLeft="0px" nzAlign="center">
              {{ data.rag_soc }}
            </td>
            <td nzLeft="0px" nzAlign="center">
              <div class="uiQuoteData">
                <p *ngIf="data.data_conferma" style="font-size: 12px; margin: 0">
                  <span class="uiDataSpan">CONFERMA: </span>
                  <span *ngIf="data.data_autore">{{ data.data_autore }}<br /></span>
                  {{ data.data_conferma * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}
                </p>
                <p *ngIf="!data.data_conferma && data.data_modifica" style="font-size: 12px; margin: 0">
                  <span *ngIf="data.data_autore">{{ data.data_autore }}<br /></span>
                  <b>{{ data.data_modifica * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}</b>
                </p>
              </div>
            </td>
            <td nzLeft="0px" nzAlign="center">
              <span *ngIf="data.cVendId">{{ data.cVendId }}: {{ data.cVendNome }} {{ data.cVendCognome }}</span>
            </td>
            <td nzLeft="0px" nzAlign="center">
              {{ data.tipologia }}
            </td>
            <td nzLeft="0px" nzAlign="center">
              {{ data.stato }}
            </td>
            <td nzLeft="0px" nzAlign="center">
              <a *ngIf="data.versione == 'a'" nz-popconfirm nzPopconfirmTitle="{{ 'deleteAlert' | translate }}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteQuote(data.cd_preventivo, null)" nzTooltipTitle="{{ 'deleteQuote' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                <i class="fad fa-trash red-trash"></i>
              </a>
              <a
                *ngIf="data.versione != 'a'"
                nz-popconfirm
                nzPopconfirmTitle="{{ 'deleteVersionAlert' | translate }}"
                nzPopconfirmPlacement="top"
                (nzOnConfirm)="deleteQuote(data.cd_preventivo, data.versione)"
                nzTooltipTitle="{{ 'deleteQuoteVersion' | translate }}"
                nzTooltipPlacement="top"
                nz-tooltip
              >
                <i class="fad fa-trash"></i>
              </a>
            </td>
            <td nzLeft="0px" nzAlign="center" class="uiDownloadQuote">
              <button nz-button (click)="duplicate(data.cd_preventivo)" class="uiDownloadBtn" nzTooltipTitle="{{ 'copyQuote' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                <i class="fad fad-swap-color fa-copy"></i>
              </button>
            </td>
            <td nzLeft="0px" nzAlign="center" class="uiDownloadQuote">
              <!-- <button nz-button [disabled]="data.stato === 'In corso'" class="uiDownloadBtn" (click)="exportExcel(data)" nzTooltipTitle="{{'exportCsv' | translate}}" nzTooltipPlacement="top" nz-tooltip> -->
              <button nz-button class="uiDownloadBtn" (click)="exportExcel(data)" nzTooltipTitle="{{ 'exportCsv' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                <i class="fad fad-swap-color fa-file-excel"></i>
              </button>
              <button nz-button [disabled]="data.stato === 'In corso'" class="uiDownloadBtn" (click)="downloadPdf(data.cd_preventivo)" nzTooltipTitle="{{ 'downloadPdf' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                <i class="fad fad-swap-color fa-download"></i>
              </button>
            </td>
            <td nzLeft="0px" nzAlign="center" class="uiDownloadQuote">
              <button nz-button class="uiDownloadBtn" (click)="openPdf(data.cd_preventivo)" nzTooltipTitle="{{ 'quotePreview' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                <i class="fad fad-swap-color fa-eye"></i>
              </button>
            </td>
            <td nzLeft="0px" nzAlign="center">
              <a
                nz-popconfirm
                nzPopconfirmTitle="Vuoi creare una nuova versione?"
                nzPopconfirmPlacement="top"
                nzOkText="Crea versione"
                nzCancelText="Visualizza"
                (nzOnCancel)="openQuote(data.cd_preventivo, false)"
                (nzOnConfirm)="openQuote(data.cd_preventivo, true)"
                *ngIf="data.stato === 'Completato'"
                nzTooltipTitle="{{ 'openQuote' | translate }}"
                nzTooltipPlacement="top"
                nz-tooltip
              >
                <i class="fad fad-swap-color fa-folder-open"></i>
              </a>
              <a (click)="openQuote(data.cd_preventivo, false)" *ngIf="data.stato === 'In corso'" nzTooltipTitle="{{ 'openQuote' | translate }}" nzTooltipPlacement="top" nz-tooltip>
                <i class="fad fad-swap-color fa-folder-open"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-content>
  </nz-spin>
</nz-layout>
