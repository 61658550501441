import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { ImgFilterService } from 'src/app/services/img-filter.service';
import ImgFilterFunctions from 'src/app/services/imgFilterFunctions';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-img-modal',
  templateUrl: './imgModal.component.html',
  styleUrls: ['./imgModal.component.scss']
})
export class ImgModalComponent extends ImgFilterFunctions implements OnInit {
  @Input() cd_prodotto: string;

  constructor(private modal: NzModalRef, data: DataService, message: NzMessageService, translate: TranslateService, private g: GlobalService,
    private http: HttpClient, private elementRef: ElementRef, private storage: StorageService, private auth: AuthService, imgFilterService: ImgFilterService) {
    super(data, message, translate, imgFilterService);
  }
  initialImgs: any[] = [];

  previewImage: string | undefined = '';
  previewVisible = false;
  uploadURL = this.g.phpPath + '/products/addProductImage.php';

  @ViewChild('virtualTable', { static: false }) nzTableComponent?: NzTableComponent<any>;
  private destroy$ = new Subject();

  scrollToIndex(id: any): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(id);
  }

  trackByIndex(_: number, data: any): number {
    return data.id;
  }

  ngAfterViewInit(): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrolledIndexChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number) => {
        console.log('scroll index to', data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit() {

    await this.imgFilterService.getImgFilters();
    console.log(this.cd_prodotto);
    if (this.cd_prodotto !== '00.00') {
      await this.getProductImages();
    } else {
      await this.getTempProdImages();
    }
    console.log(4);
    this.addDataToImg();
  }

  async getProductImages() {
    try {
      const result = await this.data.getProductImages(this.cd_prodotto).toPromise();
      let selectedImgs: string[] = this.storage.getSelectedImages();
      let jsonResult;
      if (result) {
        jsonResult = JSON.parse(result);
      } else {
        jsonResult = JSON.parse('[]');
      }
      if (jsonResult.length > 0) {
        jsonResult.forEach(el => {
          let link = this.g.imgPath + 'prodotti/' + el;
          let selected;
          if (selectedImgs.includes(el)) {
            selected = true;
          } else {
            selected = false;
          }
          this.dataResult.push(
            {
              id: this.dataResult.length,
              name: el,
              url: link,
              selected: selected
            }
          );
        });
        this.initialImgs = this.dataResult.slice();
      } else {
        this.dataResult = jsonResult;
        this.initialImgs = this.dataResult.slice();
      }
      this.loading = false;
    } catch (error) {
      this.message.create('error', 'Errore', { nzDuration: 5000 });
      this.loading = false;
      console.error('GET TABLE: ', error);
    }
  }

  async getTempProdImages() {
    try {
      const result = await this.data.getTempProdImages().toPromise();

      console.log({ result });
      let selectedImgs: string[] = this.storage.getSelectedImages();
      let jsonResult;
      if (result) {
        jsonResult = result;
      } else {
        jsonResult = JSON.parse('[]');
      }
      console.log(jsonResult);
      if (jsonResult.length > 0) {
        jsonResult.forEach(el => {
          let link = this.g.imgPath + 'prodotti/' + el;

          let selected;
          if (selectedImgs.includes(el)) {
            selected = true;
          } else {
            selected = false;
          }
          this.dataResult.push(
            {
              id: this.dataResult.length,
              name: el,
              url: link,
              selected: selected
            }
          );

        });
        console.log(this.dataResult);
        this.initialImgs = this.dataResult.slice();
      } else {
        this.dataResult = jsonResult;
        this.initialImgs = this.dataResult.slice();
      }
      this.loading = false;

    } catch (error) {
      console.log(error);

    }
  }

  getBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /*
   * @desc change image action. save image throught php: item.action
   * @param item -> uploaded icon name
   * @return void
   */
  customImgReq = (item) => {
    this.showTable = true;
    // Create a FormData here to store files and other parameters.
    const ext = item.file.name.substr(item.file.name.lastIndexOf('.'));
    let filename = '';
    if (this.cd_prodotto !== '00.00') {
      filename = this.cd_prodotto + '-' + this.dataResult.length + '-' + new Date(Date.now()).getTime() + ext;
    } else {
      filename = this.cd_prodotto + '-' + new Date(Date.now()).getTime() + ext;
    }
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('filename', filename);
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });

    return this.http.request(req).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total! > 0) {
          (event as any).percent = (event.loaded / event.total!) * 100;
        }
        item.onProgress!(event, item.file!);
      } else if (event instanceof HttpResponse) {
        item.onSuccess!(event.body, item.file!, event);
        let link = this.g.imgPath + 'prodotti/' + filename;
        this.dataResult = [
          {
            id: this.dataResult.length,
            name: filename,
            url: link,
            selected: true
          },
          ...this.dataResult];
        // this.dataResult.push(
        //   {
        //     id: this.dataResult.length,
        //     name: filename,
        //     url: link,
        //     selected: true
        //   }
        // );
        this.addDataToImg();
        this.editId = this.dataResult.length - 1;
        this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(0);
      }
    },
      err => {
        item.onError!(err, item.file!);
      }
    );
  };

  selectImg(img) {
    img.selected = !img.selected;
  }

  async onClickSave() {

    if (this.editId !== null) {
      this.message.error('Devi salvare prima di modificare un altro elemento');
      return;
    }

    let selectedImgs: string[] = [];
    let imgsLink: string[] = [];
    let imgsName: string[] = [];
    let initImgsName: string[] = [];

    // ACTUAL IMGS ARRAY
    this.dataResult.forEach(img => {
      if (img.selected) {
        selectedImgs.push(img.name);
      }
      imgsName.push('"' + img.name + '"');
      if (this.cd_prodotto === '00.00') {
        imgsLink.push(img.link);
      }
    });

    //INITIAL IMGS ARRAY
    this.initialImgs.forEach(img => {
      initImgsName.push('"' + img.name + '"');
    });

    let arrayString: string = '[' + imgsName.toString() + ']';
    let arrayStringInit: string = '[' + initImgsName.toString() + ']';

    if (this.cd_prodotto !== '00.00') {
      const checkSumKey = await this.g.encryptDataGlobal(arrayString + this.cd_prodotto);
      const formData = new FormData();
      formData.append('cd', this.cd_prodotto);
      formData.append('images', arrayString);
      formData.append('initimages', arrayStringInit);
      formData.append('checkKey', checkSumKey);

      this.data.updateProdImages(formData).subscribe(
        result => {
          this.storage.setSelectedImages(selectedImgs);
          this.modal.triggerOk();
          this.modal.destroy();
        },
        error => {
          this.message.create('error', 'Errore', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    } else {
      const checkSumKey = await this.g.encryptDataGlobal(arrayString + this.cd_prodotto);
      const formData = new FormData();
      formData.append('cd', this.cd_prodotto);
      formData.append('images', arrayString);
      formData.append('initimages', arrayStringInit);
      formData.append('checkKey', checkSumKey);

      this.data.updateProdImages(formData).subscribe(
        result => {
          this.storage.setTempImages(this.dataResult);
          this.storage.setSelectedImages(selectedImgs);
          this.modal.triggerOk();
          this.modal.destroy();
        },
        error => {
          this.message.create('error', 'Errore', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );

    }
  }
}
