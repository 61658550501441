import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { GlobalService } from "../shared/global.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private baseUrl: string;
  private header = new HttpHeaders();
  private selectedContact;
  private selectedImgs;
  constructor(private http: HttpClient, private globalService: GlobalService) {
    this.baseUrl = this.globalService.phpPath;
  }

  /**
   * @desc call backend and get key storaged in it
   * @param -
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getKey(): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .get<any>(this.baseUrl + "/getKey.php", { headers: this.header })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc get all data in requested table
   * @param request -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getTable(request: string): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/getTable.php", request, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc get all data in requested table
   * @param request -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getCustomersBySeller(request: string): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/customers/getCustomersBySeller.php", request, { headers: this.header })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  addUser(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/users/addUser.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblUser row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  editUser(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/users/editUser.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblUser row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getUsersName(): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/users/getUsersName.php", {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblUser row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  addUserAccess(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/users/addAccess.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }
  /**
   * @desc call backend and edit TblUser row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  editUserSwitch(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/users/editUserSwitch.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblUser row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  deleteUser(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/users/deleteUser.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc get all data in requested table
   * @param request -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getCustomerContacts(request: string): Observable<Error | any[]> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/customers/getCustomerContacts.php", request, { headers: this.header })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblVenditore row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  editSeller(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/sellers/editSeller.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblSeller row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  deleteSeller(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/sellers/deleteSeller.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  addSeller(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/sellers/addSeller.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblUser row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getSellersName(): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/sellers/getSellersName.php", {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  newQuote(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/quotes/newQuote.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  getQuoteById(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "text/html; charset=utf-8");
    return this.http
      .post<any>(this.baseUrl + "/quotes/getQuoteById.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  getQuotesByUser(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/quotes/getQuotesByUser.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  getAllQuotes(): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/quotes/getQuotes.php", {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  addQuoteVersion(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/quotes/addQuoteVersion.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  saveContact(c) {
    this.selectedContact = c;
  }
  getContact() {
    return this.selectedContact;
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getFamiliesByCat(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/products/getFamiliesByCat.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getProductsByFam(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/products/getProductsByFam.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getProductsByString(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/products/getProductsByString.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getProductById(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/products/getProductById.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc get all data in requested table
   * @param request -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getProductImages(request: string): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/products/getProductImages.php", request, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc get all data in requested table
   * @param request -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  getTempProdImages(): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/products/getTempImgs.php", {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  saveProductImage(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/products/addProductImage.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  updateProdImages(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/products/updateProdImages.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  addQuoteRow(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/addQuoteRow.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  deleteQuoteRow(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/deleteQuoteRow.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  saveQuote(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/saveQuote.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  deleteQuote(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/deleteQuote.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  deleteQuoteVersion(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/deleteQuoteVersion.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  duplicateQuote(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/duplicateQuote.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }
  getRowById(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/getRowById.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  editQuoteRow(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/editQuoteRow.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  updateQuoteRow(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/updateQuoteRow.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  addProduct(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/products/addProduct.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  deleteProduct(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/products/deleteProduct.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  editProduct(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/products/editProduct.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }
  editSetting(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/settings/editSetting.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  deleteProductImage(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/products/deleteProductImage.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  getRowImages(request: string): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/getRowImages.php", request, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  generatePdf(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/pdfmodels/model1.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  exportExcel(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/quotes/exportQuoteRowsById.php", formData, {
        headers: this.header,
        responseType: "text" as any,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  updateRowColor(formData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/quotes/updateRowColor.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  getLastTemporary(): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + "/customers/getLastTemporary.php", {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  addCustomer(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/customers/addCustomer.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and edit TblClienti row
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  deleteCustomer(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/customers/deleteCustomer.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  addCustomerContact(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/customers/addCustomerContact.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  editCustomerCode(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/customers/editCustomerCode.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  editRowOrder(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/quotes/editRowOrder.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  /**
   * @desc call backend and add row to TblUser
   * @param formData -> contains request
   * @return Observable<Error | any>, any if it works, error otherwise
   */
  addCategory(formData: FormData): Observable<Error | any> {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any>(this.baseUrl + "/users/addUser.php", formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            return throwError(err);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  edit(formData: FormData, path: string) {
    this.header.append("Content-Type", "application/json");
    return this.http
      .post<any[]>(this.baseUrl + path, formData, {
        headers: this.header,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  // ****************** 2023/07/10 Alberto Baggio *****************************************************************************************
  getStandardCover() {
    this.header.append("Content-Type", "application/json");
    return this.http.get<any>(this.baseUrl + '/quotes/getStandardCover.php', { headers: this.header });
  }

  setStandardCover(formData: FormData) {
    this.header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + '/quotes/setStandardCover.php', formData, { headers: this.header });
  }
  // *****************************************************************************************************************************************

  // ****************** 2024/10/14 Alberto Baggio *****************************************************************************************
  getTags() {
    this.header.append("Content-Type", "application/json");
    return this.http.get<any>(this.baseUrl + '/tags/getTags.php', { headers: this.header });
  }

  createTag(formData) {
    this.header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + '/tags/createTag.php', formData, { headers: this.header });
  }

  editTag(formData) {
    this.header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + '/tags/editTag.php', formData, { headers: this.header });
  }

  deleteTag(formData) {
    this.header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + '/tags/deleteTag.php', formData, { headers: this.header });
  }

  getImgFilter() {
    this.header.append("Content-Type", "application/json");
    return this.http.get<any>(this.baseUrl + '/imgFilter/getImgFilter.php', { headers: this.header });
  }

  setImgFilter(formData) {
    this.header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + '/imgFilter/setImgFilter.php', formData, { headers: this.header });
  }

  deleteImgFilter(formData) {
    this.header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + '/imgFilter/deleteImgFilter.php', formData, { headers: this.header });
  }
}
