import { CategoriesComponent } from './categories/categories.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroModule } from 'src/app/ng-zorro.module';
import { UsersComponent } from './users/users.component';
import { ProductsComponent } from './products/products.component';
import { SellersComponent } from './sellers/sellers.component';
import { QuotesComponent } from './quotes/quotes.component';
import { CustomersComponent } from './customers/customers.component';
import { SettingsComponent } from './settings/settings.component';
import { QuillModule } from 'ngx-quill';
import { TagsComponent } from './tags/tags.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AdminComponent,
    UsersComponent,
    ProductsComponent,
    CategoriesComponent,
    SellersComponent,
    QuotesComponent,
    CustomersComponent,
    SettingsComponent,
    TagsComponent,
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgZorroModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ size: ['small', false, 'large', 'huge'] }],
        ],
      },
      placeholder: 'Inserisci testo...',
      theme: 'snow',
    }),
  ],
  exports: [AdminComponent, NgZorroModule],
  providers: [AuthService, AuthGuard],
})
export class AdminModule { }
