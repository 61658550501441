<nz-layout class="uiUserAdminInnerLayout">
  <!--
    <nz-content *ngIf="!loading">
        <span>{{'prevPrefix' | translate}}</span> <br />
        <input nz-input [(ngModel)]="settings.prefisso_preventivo" />
    </nz-content>
     -->
  <nz-content>
    <nz-table #dataTable nzBordered nzSize="middle" [nzData]="tagsList" [nzPageSize]="30" [nzLoading]="loading" nzPaginationPosition="none" [nzSimple]="true" class="uiAdminTable">
      <thead>
        <tr>
          <th nzAlign="center">Nome</th>
          <th nzAlign="center" nzWidth="200px">
            <a class="uiAdminAddBtn" style="font-size: large" (click)="addTag()" nzTooltipTitle="{{ 'toottipAdd' | translate }}" nzTooltipPlacement="top" nz-tooltip nz-button>
              <i class="fa fa-plus-square"></i>
            </a>
          </th>
        </tr>
      </thead>
      <tbody class="uiAdminTableBody">
        <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="i % 2 !== 0 ? 'uiColoredRow' : ''">
          <!-- <ng-container *ngIf="!editCache[data.nome].edit; else editTemplate"> -->
          <td nzLeft="0px">
            <div *ngIf="editId != data.id">
              {{ data.name }}
            </div>
            <div *ngIf="editId == data.id">
              <input type="text" nz-input [(ngModel)]="data.name" />
            </div>
          </td>
          <td nzAlign="center">
            <button *ngIf="editId != data.id" nz-button nzType="text" (click)="edit(data.id)">
              <i class="fad fa-edit uiAdminUserAction" nzTooltipTitle="{{ 'editSetting' | translate }}" nzTooltipPlacement="top" nz-tooltip></i>
            </button>
            <button *ngIf="editId != data.id" nz-button nzType="text" nz-popconfirm nzPopconfirmTitle="Vuoi cancellare il tag?" nzPopconfirmPlacement="bottom" (nzOnConfirm)="delete(data.id)">
              <i class="fad fa-trash uiAdminUserAction" nzTooltipTitle="{{ 'delete' | translate }}" nzTooltipPlacement="top" nz-tooltip></i>
            </button>
            <button *ngIf="editId == data.id" nz-button nzType="text" (click)="save()">
              <i class="fad fa-save uiAdminUserAction" nzTooltipTitle="{{ 'save' | translate }}" nzTooltipPlacement="top" nz-tooltip></i>
            </button>
            <button *ngIf="editId == data.id" nz-button nzType="text" (click)="back()">
              <span style="padding: 0" nz-icon nzType="close" nzTheme="outline" nzTooltipTitle="{{ 'close' | translate }}" nzTooltipPlacement="top" nz-tooltip></span>
            </button>
          </td>
          <!-- <td *ngIf="!checkObj(data.valore) && data.tipo !== '2'" nzLeft="0px" nzAlign="center">
              {{ data.valore }}
            </td>
            <td *ngIf="checkObj(data.valore) && data.tipo !== '2'" nzLeft="0px" nzAlign="left">
              <ng-container *ngFor="let item of data.valore; let i = index"> {{ item }}<br /> </ng-container>
            </td>
            <td nzLeft="0px" nzAlign="left" *ngIf="data.tipo === '2'" class="uiQuoteClauses">
              <div class="uiQuoteClausesForm">
                <div nz-row [nzGutter]="16" class="uiClausesRow uiClausesBodyRow">
                  <div nz-col [nzSpan]="24" class="uiClausesCol">
                    <div class="uiClausesInner" [innerHTML]="data.valore"></div>
                  </div>
                </div>
              </div>
            </td>
            <td nzLeft="0px" nzAlign="center">
              <i class="fad fa-edit uiAdminUserAction" (click)="startEdit(data.nome)" nzTooltipTitle="{{ 'editSetting' | translate }}" nzTooltipPlacement="top" nz-tooltip></i>
            </td> -->
          <!-- </ng-container> -->
          <!-- <ng-template #editTemplate>
            <td nzLeft="0px" nzAlign="center">
              {{ data.nome }}
            </td>
            <td nzLeft="0px" nzAlign="center" *ngIf="data.tipo === '0'">
              <input type="text" nz-input [(ngModel)]="editCache[data.nome].data.valore" />
            </td>

            <td nzLeft="0px" nzAlign="center" *ngIf="data.tipo === '1'">
              <div class="uiAddOptionContainer" *ngFor="let val of editCache[data.nome].data.valore; let j = index">
                <input class="uiAddOption" type="text" nz-input [ngModel]="editCache[data.nome].data.valore[j]" (change)="onValueUpdate($event, data.nome, j)" />
                <a (click)="deleteInput(data.nome, j)"><i class="fad fa-minus"></i></a>
              </div>
              <a (click)="addInput(data.nome)"><i class="fad fa-plus uiAddInputIcon"></i>{{ 'add-input' | translate }}</a>
              <br />
            </td>

            <td nzLeft="0px" nzAlign="left" *ngIf="data.tipo === '2'" class="uiQuoteClauses">
              <div class="uiQuoteClausesForm">
                <div nz-row [nzGutter]="16" class="uiClausesRow uiClausesBodyRow">
                  <div nz-col [nzSpan]="24" class="uiClausesCol">
                    <quill-editor [ngClass]="'uiQuillIta uiCluauseTextarea'" [styles]="{ height: '400px' }" [(ngModel)]="editCache[data.nome].data.valore"></quill-editor>
                  </div>
                </div>
              </div>
            </td>

            <td nzLeft="0px" nzAlign="center">
              <a (click)="saveEdit(data.nome)" class="save">{{ 'save' | translate }}</a>
              <br />
              <a (click)="cancelEdit(data.nome)">{{ 'cancel' | translate }}</a>
            </td>
          </ng-template> -->
        </tr>
      </tbody>
    </nz-table>
  </nz-content>
</nz-layout>
