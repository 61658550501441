import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NZ_I18N, en_US, it_IT } from 'ng-zorro-antd/i18n';
import { LoginComponent } from './pages/login/login.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { NgZorroModule } from './ng-zorro.module';
import { AdminModule } from './pages/admin/admin.module';
import { HistoryComponent } from './pages/history/history.component';
import { QuoteComponent, SafeUrlPipe } from './pages/quote/quote.component';
import { ContactModalComponent } from './pages/quote/contactModal/contactModal.component';
import { EditComponent } from './pages/edit/edit.component';
import { AddComponent } from './pages/add/add.component';
import { QuillModule } from 'ngx-quill';
import { SwiperModule } from 'swiper/angular';
import { ImgModalComponent } from './pages/add/imgModal/imgModal.component';
import { ImgEditModalComponent } from './pages/edit/imgModal/imgModal.component';
import { ProductAddComponent } from './pages/product-add/product-add.component';
import { ProductEditComponent } from './pages/product-edit/product-edit.component';
import { ImgEditProductModalComponent } from './pages/product-edit/imgModal/imgModal.component';
import { ImgProductModalComponent } from './pages/product-add/imgModal/imgModal.component';
import { NoCommaPipe } from './shared/no-comma.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';

registerLocaleData(it);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WelcomeComponent,
    HistoryComponent,
    QuoteComponent,
    SafeUrlPipe,
    ContactModalComponent,
    EditComponent,
    AddComponent,
    ImgModalComponent,
    ImgEditModalComponent,
    ProductAddComponent,
    ProductEditComponent,
    ImgEditProductModalComponent,
    ImgProductModalComponent,
    NoCommaPipe,
  ],
  imports: [
    AdminModule,
    BrowserModule,
    AppRoutingModule,
    NgZorroModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ size: ['small', false, 'large', 'huge'] }],
        ],
      },
      placeholder: 'Inserisci testo...',
      theme: 'snow',
    }),
    SwiperModule,
  ],
  exports: [NgZorroModule],
  providers: [AuthService, AuthGuard, { provide: NZ_I18N, useValue: it_IT }],
  bootstrap: [AppComponent],
})
export class AppModule { }
