import { ImgFilterService } from 'src/app/services/img-filter.service';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  constructor(private data: DataService, private message: NzMessageService) { }


  tagsList: any[] = [];
  loading = true;
  editId: string | null = null;

  async ngOnInit() {
    await this.getTags();
  }

  async getTags() {
    try {
      const results = await this.data.getTags().toPromise();
      console.log(results);
      this.tagsList = results;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  addTag() {
    if (this.editId !== null) { this.message.create('error', 'Devi salvare prima di modificare un altro elemento'); return; }
    this.tagsList = [{ id: "0", name: "" }, ...this.tagsList];
    this.editId = "0";
  }

  back() {
    this.loading = true;
    console.log(this.editId == "0");
    if (this.editId == "0") this.tagsList = this.tagsList.filter((el) => el.id != "0");
    console.log(this.tagsList);
    this.editId = null;
    this.loading = false;
  }

  edit(id) {
    if (this.editId !== null) { this.message.create('error', 'Devi salvare prima di modificare un altro elemento'); return; }
    this.editId = id;
  }

  async save() {

    this.loading = true;
    const editName = this.tagsList.find((el) => el.id === this.editId).name;
    console.log(editName);
    try {
      let result;
      if (this.editId == "0") {
        result = await this.data.createTag({ name: editName }).toPromise();
      } else {
        console.log({ id: this.editId, name: editName });
        result = await this.data.editTag({ id: this.editId, name: editName }).toPromise();
      }
      console.log(result);
    } catch (error) {
      console.error({ error });
    }
    this.editId = null;
    this.getTags();
  }

  async delete(id) {
    this.loading = true;
    try {
      await this.data.deleteTag({ id }).toPromise();
    } catch (error) {
      console.error(error);
    }
    this.getTags();

  }





}
