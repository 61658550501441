import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";
import { NzFormatEmitEvent, NzTreeNode } from "ng-zorro-antd/tree";
import { NzMessageService } from "ng-zorro-antd/message";
import { TblProdotti } from "src/app/entities/TblProdotti";
import { Location } from "@angular/common";
import { SwiperComponent } from "swiper/angular";
import { DomSanitizer } from "@angular/platform-browser";

// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from "swiper/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { ImgProductModalComponent } from "./imgModal/imgModal.component";
import { StorageService } from "src/app/services/storage.service";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, Zoom, Autoplay, Thumbs, Controller]);

@Component({
  selector: "app-product-add",
  templateUrl: "./product-add.component.html",
  styleUrls: ["./product-add.component.scss"],
})
export class ProductAddComponent implements OnInit {
  searchValue = "";
  searchString = "";
  categories: any[] = [];
  loading = true;
  visible = true;
  selectedProd = "";
  product: any;
  selectedImgs: any[] = [];
  fornitore = false;
  totQuote = 0;
  selectedCat = "";
  selectedFam = "";
  constructor(
    private translate: TranslateService,
    private g: GlobalService,
    private data: DataService,
    private message: NzMessageService,
    private location: Location,
    private sanitizer: DomSanitizer,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private storage: StorageService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild("treeNodes") tree;
  @ViewChild("catSelect") catSelect;
  @ViewChild("famSelect") famSelect;

  ngOnInit(): void {
    this.data.getTable("categories").subscribe(
      (result) => {
        this.categories = result;
        this.loading = false;
        this.product = {
          cd_prodotto: "",
          nome: "",
          descrizione: "",
          cd_fam: "",
          note: "",
          note_interne: "",
          um: "nr",
          quantita: 0,
          pr_fornitore: 0,
          sc_materiale: 0,
          sc_extra: 0,
          unit_materiale: 0,
          unit_manodopera: 0,
          perc_manodopera: 0,
          ricarico: 0.68,
          immagini: [],
        };
        this.storage.setSelectedImages([]);
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        this.loading = false;
        console.error("GET TABLE: ", error);
      }
    );
  }

  /**
   * @desc after view init, set g.actualpage. Used in header.
   * @param -
   * @return void
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("addProd");
    });
  }

  async saveInsert() {
    let imgsName: string[] = [];
    this.storage.getSelectedImages().forEach((img) => {
      imgsName.push('"' + img + '"');
    });
    let arrayString: string = "[" + imgsName.toString() + "]";

    let insert = {
      cd_prodotto: this.product.cd_prodotto,
      nome: this.product.nome,
      descrizione: this.product.descrizione,
      cd_fam: this.product.cd_fam,
      note: this.product.note,
      note_interne: this.product.note_interne,
      um: this.product.um,
      quantita: this.product.quantita,
      perc_manodopera: this.product.perc_manodopera,
      sc_materiale: this.product.sc_materiale,
      sc_extra: this.product.sc_extra,
      unit_manodopera: this.product.unit_manodopera,
      unit_materiale: this.product.unit_materiale,
      pr_fornitore: this.product.pr_fornitore,
      ricarico: this.product.ricarico,
      immagini: arrayString,
    };

    console.log("INS:", insert);
    const jsonInsert = JSON.stringify(insert);

    const checkSumKey = await this.g.encryptDataGlobal("TblPreventivi" + jsonInsert);
    const formData = new FormData();
    formData.append("insert", jsonInsert);
    formData.append("checkKey", checkSumKey);

    console.log("FORMDATA:", formData);

    this.data.addProduct(formData).subscribe(
      (result) => {
        this.storage.removeSelectedImages();
        this.location.back();
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  back() {
    this.storage.removeSelectedImages();
    this.location.back();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  openImageModal() {
    this.storage.setSelectedImages(this.storage.getSelectedImages());
    const modal = this.modal.create({
      nzTitle: "Scegli immagini",
      nzContent: ImgProductModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClassName: "uiImgModal w-100",
      nzComponentParams: {
        cd_prodotto: this.product.cd_prodotto,
      },
      nzOnOk: () => {
        this.selectedImgs = this.storage.getSelectedImages();
      },
    });
  }

  calculateMan(e) {
    this.product.unit_manodopera = this.product.pr_fornitore * (this.product.perc_manodopera / 100);
  }

  calculateMat(e) {
    this.product.unit_materiale =
      this.product.pr_fornitore - (this.product.pr_fornitore * this.product.sc_materiale) / 100;
    this.product.unit_materiale =
      this.product.unit_materiale - (this.product.unit_materiale * this.product.sc_extra) / 100;
  }

  async onSelectFam(e) {
    const id = e;
    const checkSumKey = await this.g.encryptDataGlobal("TblProdotti" + id);
    const formData = new FormData();
    formData.append("cd", id);
    formData.append("checkKey", checkSumKey);
    this.data.getProductsByFam(formData).subscribe(
      (result) => {
        let lastCdS = result[result.length - 1].key.split(".")[1];
        var lastCd: number = +lastCdS + 1;
        lastCdS = lastCd.toString();
        console.log(lastCdS, lastCdS.length);
        for (let i = 0; i < 3 - lastCdS.length; i++) {
          lastCdS = "0" + lastCdS;
        }
        this.product.cd_fam = this.selectedFam;
        this.product.cd_prodotto = this.selectedFam + "." + lastCdS;
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  toFloat(value: string) {
    return Number(parseFloat(value)).toFixed(2);
  }

  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(" %", "");

  enableList() {
    this.fornitore = !this.fornitore;
    this.product.unit_materiale = this.toFloat(this.product.unit_materiale);
    this.product.unit_manodopera = this.toFloat(this.product.unit_manodopera);
  }

  catChanged($event) {
    this.changeDetectorRef.detectChanges();
    this.selectedFam = "";
    if (this.famSelect) {
      this.famSelect.focus();
      this.famSelect.nzOpen = true;
    }
  }

  famChanged($event) {
    this.product.cd_fam = $event;
  }
}
